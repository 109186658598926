<template>
    <div
        class="my-4 d-stepper col-8 mx-auto d-flex align-items-start justify-content-center"
    >
        <div
            v-for="(stepItem, index) in steps"
            :key="index"
            class="d-flex align-items-start justify-content-center flex-column flex-md-row"
        >
            <div class="d-flex flex-column align-items-center w-28">
                <step-viewer
                    :index="index"
                    :step="step"
                    :info="stepItem"
                    @click="onClick"
                />
            </div>

            <div
                v-if="index < steps.length - 1"
                class="d-none mt-2 bg-secondary d-lg-flex align-items-center text-center rounded py-1 px-1 h-2 w-20"
            >
                <div
                    v-show="step > index"
                    class="w-100 text-white text-sm inline-block bg-success px-1 rounded h-1"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import StepViewer from "./StepViewer.vue";

export default {
    name: "StepsViewer",
    components: { StepViewer },
    props: {
        steps: {
            type: Array,
            default: () => [],
            required: true
        },
        step: {
            type: Number,
            default: () => 0,
            required: true
        }
    },
    emit: ["click"],
    methods: {
        onClick(index) {
            this.$emit("click", index);
        }
    }
};
</script>
