<template>
    <div class="border-bottom border-secondary w-100 pb-4">
        <div>
            <div
                class="col-12 d-flex justify-content-between align-items-center"
            >
                <div class="d-flex flex-lg-row flex-column">
                    <span>
                        <strong> Fila #&nbsp; {{ error.index }}</strong>
                    </span>
                </div>
                <div class="d-flex">
                    <vs-button
                        :class="isActive ? 'bg-success' : 'bg-secondary'"
                        icon
                        border
                        circle
                        @click="sendActive"
                    >
                        {{ isActive ? "➰" : "🔰" }}
                    </vs-button>
                </div>
            </div>
        </div>

        <b-collapse ref="container" :visible="isActive">
            <div class="d-flex flex-wrap">
                <div
                    v-for="item in base"
                    :key="item.key"
                    class="col-12 col-md-6 mt-5 d-flex flex-column"
                >
                    <div class="position-relative">
                        <label class="left-13px font-weight-bold">
                            {{ item.name }} :
                        </label>
                    </div>
                    <div class="d-flex px-2 align-items-center h-100">
                        <small class="ml-2">{{ error.item[item.key] }}</small>
                    </div>
                </div>
                <div class="col-12 mt-3 d-flex flex-column">
                    <span class="h3 text-danger"> Errores : </span>
                    <ul class="">
                        <li v-for="(item, index) in error.reasons" :key="index">
                            {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: "ErrorViewer",
    props: {
        error: {
            default: () => ({
                index: 1,
                item: {}
            }),
            type: Object,
            required: true
        },
        active: {
            type: Number,
            default: 0,
            required: false
        },
        base: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["changeActive"],
    data: () => ({
        showOptions: false
    }),
    computed: {
        isActive() {
            return this.active === this.error.index;
        }
    },
    mounted() {},
    methods: {
        sendActive() {
            if (this.error.index === this.active) {
                this.$emit("changeActive", 0);
            } else {
                this.$emit("changeActive", this.error.index);
            }
        }
    }
};
</script>
