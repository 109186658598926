<template>
    <div class="border-bottom border-secondary w-100 mb-2">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-lg-row flex-column">
                <strong>
                    Código de Barras {{ referenceComponent.barcode }}
                </strong>
            </div>
            <div class="d-flex">
                <small
                    v-if="referenceComponent.updatedAt"
                    class="text-center font-weight-normal"
                >
                    📆 {{ referenceComponent.updatedAt | date }}
                </small>

                <div v-if="enabled" id="actions" class="d-flex">
                    <vs-button danger icon @click="remove">🗑</vs-button>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap">
            <div
                v-if="reference.currentStock !== undefined"
                class="col-12 my-2"
            >
                <span>
                    ⚠⚠ La referencia ya esta en el inventario del comercio, este
                    proceso no realiza actualizaciones de inventario, para este
                    registro solo se actualizará el producto y la referencia.
                    Para actaualizar el inventario usa el proceso de carga
                    masiva de inventario.
                </span>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <input-money-component
                    v-model="referenceComponent.price"
                    border
                    :enabled="enabled"
                    label="Precio sin descuento"
                    icon="💲"
                    :message-error="
                        !referenceComponent.price
                            ? 'El precio sin descuento no puede ser cero'
                            : ''
                    "
                    @blur="updateReference"
                />
                <div v-if="reference.currentPriceOffer !== undefined">
                    <small>
                        El precio sin descuento actual es
                        {{ reference.currentPrice | money }}
                    </small>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <input-money-component
                    v-model="referenceComponent.priceOffer"
                    border
                    :enabled="enabled"
                    label="Precio final"
                    icon="💲"
                    :message-error="
                        referenceComponent.priceOffer > referenceComponent.price
                            ? 'El precio final debe ser menor que el precio'
                            : !referenceComponent.priceOffer
                            ? 'El precio final no puede ser cero'
                            : ''
                    "
                    @blur="updateReference"
                />
                <div v-if="reference.currentPriceOffer !== undefined">
                    <small>
                        El precio final actual es
                        {{ reference.currentPriceOffer | money }}
                    </small>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <input-money-component
                    v-model="referenceComponent.cost"
                    border
                    :enabled="enabled"
                    label="Costo"
                    icon="💲"
                    :message-error="
                        referenceComponent.cost > referenceComponent.priceOffer
                            ? 'El costo debe ser menor que el precio final'
                            : !referenceComponent.cost
                            ? 'El costo no puede ser cero'
                            : ''
                    "
                    @blur="updateReference"
                />
                <div v-if="reference.currentCost !== undefined">
                    <small>
                        El costo actual es
                        {{ reference.currentCost | money }}
                    </small>
                </div>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="referenceComponent.size"
                    border
                    shadow
                    :state="stateEnabled"
                    label="Tamaño/Talla/Valor"
                    :disabled="!enabled"
                    @blur="updateReference"
                />
                <div v-if="reference.currentSize !== undefined">
                    <small>
                        El valor actual es
                        {{ reference.currentSize }}
                    </small>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <color-input
                    v-model="color"
                    :enabled="enabled"
                    @change="updateReference"
                />
                <div v-if="reference.currentSize !== undefined">
                    <small>
                        El color actual es
                        {{ reference.currentColor }}
                    </small>
                </div>
            </div>
            <div
                v-if="reference.stock !== undefined"
                class="col-12 col-md-6 mt-5"
            >
                <vs-input
                    v-model="referenceComponent.stock"
                    border
                    shadow
                    type="number"
                    :state="stateEnabled"
                    label="Stock"
                    :disabled="!enabled || reference.currentStock !== undefined"
                    @blur="updateReference"
                />
                <div v-if="reference.currentStock !== undefined">
                    <small> Hay {{ reference.currentStock }} en stock </small>
                </div>
            </div>

            <images-manage
                :id="referenceComponent.barcode"
                v-model="referenceComponent.images"
                class="my-4"
                :enabled="enabled"
                message="referencia"
                @change="updateReference"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { DEFAULT_IMAGE_URL } from "@/common/constants";

import ColorInput from "@/components/utils/ColorInput.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ReferenceSimpleManage",
    components: {
        ColorInput,
        ImagesManage,
        InputMoneyComponent
    },
    props: {
        reference: {
            default: () => ({
                id: null,
                color: "blanco",
                colorRef: "#fff",
                cost: 30000,
                price: 50000,
                priceOffer: 40000,
                size: "unico",
                updatedAt: "2021-10-26T00:27:59.146Z",
                currentColor: "rubí",
                currentColorRef: "#e0115f",
                currentCost: 12000,
                currentPrice: 20000,
                currentPriceOffer: 20000,
                currentSize: "1ud",
                images: [
                    {
                        alt: "alt",
                        id: 1,
                        order: { order: 1 },
                        url: DEFAULT_IMAGE_URL
                    }
                ]
            }),
            type: Object,
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["update", "remove"],
    data: () => ({
        color: {
            color: "blanco",
            colorRef: "#fff"
        },
        referenceComponent: {
            id: null,
            barcode: "",
            color: "blanco",
            colorRef: "#fff",
            cost: 30000,
            price: 50000,
            priceOffer: 40000,
            size: "unico",
            updatedAt: "2021-10-26T00:27:59.146Z",
            images: [
                {
                    alt: "alt",
                    id: 1,
                    order: { order: 1 },
                    url: DEFAULT_IMAGE_URL
                }
            ]
        }
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        reference(value) {
            this.setValue(value);
        }
    },
    mounted() {
        this.setValue(this.reference);
    },
    methods: {
        setValue(reference) {
            this.color = {
                color: reference.color,
                colorRef: reference.colorRef
            };
            this.referenceComponent = { ...reference };
        },
        updateReference() {
            this.$emit("update", { ...this.referenceComponent, ...this.color });
        },
        remove() {
            this.$emit("remove", { barcode: this.reference.barcode });
        }
    }
};
</script>
