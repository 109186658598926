<template>
    <fragment>
        <component
            :is="info.enabledClicked && index < step ? 'button' : 'div'"
            v-if="step > index"
            class="rounded-circle h-6 w-6 d-flex align-items-center justify-content-center bg-success btn p-0"
            @click="onClick"
        >
            ✔
        </component>
        <div
            v-else
            class="rounded-circle h-6 w-6 d-flex align-items-center justify-content-center"
            :class="step == index ? 'bg-primary' : 'bg-secondary'"
        >
            <span class="text-white">
                {{ index + 1 }}
            </span>
        </div>

        <span
            class="text-center mt-1 font-semibold"
            :class="{
                'text-primary': step == index,
                'text-secondary': step < index,
                'text-success': step > index
            }"
        >
            {{ info.name }}
        </span>
    </fragment>
</template>

<script>
export default {
    name: "StepViewer",
    props: {
        step: {
            default: () => 1,
            required: true,
            type: Number
        },
        index: {
            default: () => 1,
            required: true,
            type: Number
        },
        info: {
            default: () => ({
                name: "",
                enabledClicked: true
            }),
            required: true,
            type: Object
        }
    },
    emit: ["click"],
    methods: {
        onClick() {
            if (this.info.enabledClicked && this.index < this.step) {
                this.$emit("click", this.index);
            }
        }
    }
};
</script>
